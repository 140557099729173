<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-format-list-bulleted-type"
      title="Action Items"
      class="px-5 py-3"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">
              Action Item
            </th>
            <th class="text-right primary--text">
              Count
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(actionitem, i) in actionitems"
          :key="`item-${i}`"
          >
            <td>{{actionitem.action_item}}</td>
            <td class="text-right">
              {{actionitem.count}}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>



  </v-container>
</template>

<script>

import axios from 'axios';
export default {
  name: 'StaffDashboard',
  data () {
    return {
      count_co:0,
      count_pa:0,
      count_vs:0,
      count_hs:0,
      actionitems: []
    }
  },
  
  created () {
    this.getAlerts()
    this.reset_actionitems()
  },

  methods:{

    getAlerts() {
        axios
        .get(process.env.VUE_APP_GET_DASHBOARD_ALERTS_URL,
        {
        params: {
            board: 'staff_dashboard',
        }
        })
        .then(response => {
            
            if (response.data)
            {
                var result = response.data
                this.count_co = result['count_co']
                this.count_pa = result['count_pa']
                this.count_vs = result['count_vs']
                this.count_hs = result['count_hs']
                this.reset_actionitems()
            }
        })
        .catch(error => {
        console.log(error)
        })
    },
  
  reset_actionitems() {

    // this.actionitems[0]['count_co']=this.count_co;
        
    this.actionitems = [
      {
        action_item: 'Change orders that need attention',
        count: this.count_co,
      },
      {
        action_item: 'Purchase agreements awaiting Homeowner signature',
        count: this.count_pa,
      },
      {
        action_item: 'Home selections awaiting Homeowner signature',
        count: this.count_vs,
      },
      {
        action_item: 'Vendor selections awaiting Homeowner signature',
        count: this.count_hs,
      }
    ]
  }

}

}
</script>


<style scoped>
.v-data-table table thead tr th {
    font-weight: 300;
    font-size: 17px;
    padding: 0 8px;
}
</style>